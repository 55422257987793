<template>
	<div class="main">
		<div class="download-link">
			<div class="logo-upload">
				<img :src="logoUrl" alt="logo" v-if="logoUrl" />
				<div class="no-logo-url" v-if="!logoUrl">
					<i class="el-icon-plus"></i>
					<div>
						上传图片
					</div>
				</div>
				<input type="file" class="logo-upload-input" @change="changeLogo" />
			</div>
			<el-input v-model="downloadLink" placeholder="请输入下载地址"></el-input>
		</div>
		<div class="bottons">
			<el-button type="primary" @click="previewQrcode(),isPreview=true">预览二维码</el-button>
			<el-button type="primary" @click="saveCode">下载二维码</el-button>
		</div>
		<canvas id="QRCode_header" ref="canvas" title="扫描二维码" v-show="isPreview"></canvas>
	</div>
</template>

<script>
	import QRCode from "qrcode"; //引入生成二维码插件
	export default {
		data() {
			return {
				canvasWidth: 500,
				canvasHeight: 500,
				logoUrl: '',
				downloadLink: '',
				isPreview: false
			};
		},
		methods: {
			getQRCode() {
				let opts = {
					errorCorrectionLevel: "H", //容错级别,指二维码被遮挡可以扫出结果的区域比例
					type: "image/png", //生成的二维码类型
					quality: 0.3, //二维码质量
					margin: 4, //二维码留白边距
					width: this.canvasWidth, //宽
					height: this.canvasHeight, //高
					color: {
						light: "#eaeaea" //背景色
					}
				};
				// 将获取到的数据（val）画到msg（canvas）上,加上时间戳动态生成二维码
				QRCode.toCanvas(this.$refs.canvas,
					'https://xuniuwang-global.oss-cn-hangzhou.aliyuncs.com/zdd/pkg/zdd_mer.apk', opts,
					function(error) {
						if (error) {
							console.log('加载失败！');
						}
					});
			},
			saveCode() {
				if (!this.downloadLink) {
					this.$tips({message: '请填写下载地址', type:'error'})
					return
				}
				if (!this.logoUrl) {
					this.$tips({message: '请选择logo', type:'error'})
					return
				}
				this.previewQrcode()
				setTimeout(()=>{
					//下载二维码
					let base64Img = this.$refs.canvas.toDataURL('image/jpg');
					//创建下载标签，然后设置链接和图片名称
					let a = document.createElement('a')
					a.href = base64Img
					a.download = '二维码' + Date.now()
					a.click()
					//销毁元素
					a.remove()
				},50)
			},
			previewQrcode() {
				if (!this.downloadLink) {
					this.$tips({message: '请填写下载地址', type:'error'})
					return
				}
				if (!this.logoUrl) {
					this.$tips({message: '请选择logo', type:'error'})
					return
				}
				this.getQRCode()

				//设置logo图标
				let myCanvas = this.$refs.canvas
				let ctx = myCanvas.getContext('2d')
				// 在Canvas画布 添加图片
				let img = new Image()
				img.crossOrigin = 'Anonymous'; //解决Canvas.toDataURL 图片跨域问题
				img.src = this.logoUrl;
				img.onload = () => {
					//第一个设置的元素，第二三是位置，后面两个是宽和高
					//居中的位置计算为 （二维码宽度-img宽度）/2
					let codeWidth = (this.canvasWidth * 0.70) / 2
					let codeHeight = (this.canvasHeight * 0.70) / 2
					ctx.drawImage(img, codeWidth, codeHeight, this.canvasWidth * 0.30, this.canvasHeight * 0.30)
				}
			},
			changeLogo(e) {
				const fr = new FileReader()
				fr.readAsDataURL(e.target.files[0])
				fr.onload = e => {
					this.logoUrl = e.target.result
				}
			},
		}
	}
</script>

<style scoped>
	.main {
		width: 100%;
		padding: 16px;
		box-sizing: border-box;
	}

	.download-link {
		width: 500px;
		display: flex;
		align-items: center;
	}

	.logo-upload {
		margin-top: 16px;
		width: 104px;
		height: 104px;
		background: #FAFAFA;
		border-radius: 2px;
		border: 1px solid #D9D9D9;
		text-align: center;
		display: flex;
		position: relative;
		flex-shrink: 0;
		margin-right: 16px;
	}

	.logo-upload .no-logo-url {
		margin: auto;
		font-family: PingFang SC-Regular;
		font-size: 14px;
		color: #505E5C;
		line-height: 22px;
	}

	.logo-upload .logo-upload-input {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	.logo-upload img {
		width: 100%;
		height: 100%;
	}

	.bottons {
		margin: 16px 0;
	}
</style>